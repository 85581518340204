<template>
  <div class="dashboard1 p-5">
    <h1>Netwerken in Kaart Resultaten</h1>

    <div class="mb-3 row"></div>
    <div class="mb-3 row"></div>

    <hr style="border: 3px solid black;">

    <div class="mb-3 row"></div>

    <div id="app">
      <div class="row">

        <h2>Algemeen</h2>

        <!-- <h5>Vraag preferente zorgverzekeraar</h5> -->
        <div v-for="group in [visualisatie_groep[15]]" :key="group" class="col-sm-6">
          <card :cardId="'test' + group">
            <template slot="card-header">Wie is de preferente zorgverzekeraar?</template>
            <div slot="card-body">
              <horizontal-bar-chart :marginleft="150" :xtitle="'Aantal netwerken'" :x="getSortedDataCounts(group).x"
                :y="getSortedDataCounts(group).y" />
            </div>
          </card>
        </div>


        <div class="mb-3 row"></div>
        <div class="mb-3 row"></div>

        <hr style="border: 1px solid black;">

        <div class="mb-3 row"></div>

        <!-- <h5>Vraag 1</h5> -->
        <div v-for="(group, index) in visualisatie_groep_Q1" :key="group" class="col-sm-6">
          <card :cardId="'test' + group">
            <template slot="card-header">{{ cardTitlesQ1[index] }} </template>
            <div slot="card-body">
              <horizontal-bar-chart :marginleft="150" :xtitle="'Aantal netwerken'" :x="getQuestionPerGroupQ1(group).x"
                :y="getQuestionPerGroupQ1(group).y" />
            </div>
          </card>
        </div>


      </div>

      <div class="mb-3 row"></div>
      <div class="mb-3 row"></div>

      <hr style="border: 1px solid black;">

      <div class="mb-3 row"></div>


      <!-- Vraag 3-5 -->
      <div class="row">
        <div v-for="(group, index) in [visualisatie_groep[0], visualisatie_groep[1], visualisatie_groep[2]]"
          :key="group" class="col-sm-6">
          <card :cardId="'test' + group">
            <template slot="card-header">{{ cardTitlesQ345[index] }}</template>
            <div slot="card-body">
              <horizontal-bar-chart :marginleft="300" :autoscale_xaxis=false :xtitle="'Aantal netwerken'"
                :x="getSortedDataCounts(group).x" :y="getSortedDataCounts(group).y" />
            </div>
          </card>
        </div>
      </div>


      <div class="row">
        <div class="col-sm-6">
          <card :cardId="'test' + group"
          :hovertext="'Essentiële netwerken: Huisartsengroep, Ziekenhuis of geheugenpoli, VVT, Welzijnsorganisatie, Mantelzorgorganisatie, gemeenten, organisatie die dagactiviteiten aanbieden, Alzheimer Nederland'">
            <template slot="card-header">Aantal netwerken dat samenwerkt met alle aanbevolen partners </template>
            <div slot="card-body">
              <grouped-bar-chart :marginleft="150"
                :x="[CheckSamenwerking.map(item => item.all_answers_yes === true ? 'Ja' : 'Nee')]"
                :y="[CheckSamenwerking.map(item => item.counts)]" />
            </div>
          </card>
        </div>
      </div>

      <div class="mb-3 row"></div>
      <div class="mb-3 row"></div>

      <hr style="border: 3px solid black;">

      <div class="mb-3 row"></div>

      <!-- Vraag 6 -->
      <div class="row">
        <h2>Personeel en capaciteit</h2>
        <div v-for="(group, index) in [visualisatie_groep[3], visualisatie_groep[8]]" :key="group" class="col-sm-6">
          <card :cardId="'test' + group">
            <template slot="card-header">{{ cardTitlesQ6[index] }}</template>
            <div slot="card-body">
              <grouped-bar-chart :marginleft="150" :ytitle="'Aantal netwerken'" :x="[getSortedDataCounts6_7(group).x]"
                :y="[getSortedDataCounts6_7(group).y]" />
            </div>
          </card>
        </div>
      </div>

      <div class="mb-3 row"></div>
      <div class="mb-3 row"></div>

      <hr style="border: 1px solid black;">

      <div class="mb-3 row"></div>

      <!-- Vraag 7 -->
      <div class="row">
        <div
          v-for="(group, index) in [visualisatie_groep[4], visualisatie_groep[5], visualisatie_groep[6], visualisatie_groep[7]]"
          :key="group" class="col-sm-6">
          <card :cardId="'test' + group">
            <template slot="card-header">{{ cardTitlesQ7[index] }}</template>
            <div slot="card-body">
              <grouped-bar-chart :marginleft="150" :ytitle="'Aantal netwerken'" :x="[getSortedDataCounts6_7(group).x]"
                :y="[getSortedDataCounts6_7(group).y]" />
            </div>
          </card>
        </div>
      </div>

      <div class="mb-3 row"></div>
      <div class="mb-3 row"></div>

      <hr style="border: 1px solid black;">

      <div class="mb-3 row"></div>

      <!-- Totals vraag 6,7 -->
      <div class="row">
        <div class="col-sm-6">
          <card :cardId="'test' + group">
            <template slot="card-header">Totaal aantal uur netwerkcoördinatie én ondersteuning per week</template>
            <div slot="card-body">
              <horizontal-bar-chart :marginleft="150" :xtitle="'Aantal netwerken'" :x="getSortedTotalsQ6_7().x"
                :y="getSortedTotalsQ6_7().y" />
            </div>
          </card>
        </div>
      </div>

      <div class="mb-3 row"></div>
      <div class="mb-3 row"></div>

      <hr style="border: 1px solid black;">

      <div class="mb-3 row"></div>

      <!-- Vraag 8 -->
      <div class="row">
        <div v-for="group in [visualisatie_groep[9]]" :key="group" class="col-sm-6">
          <card :cardId="'test' + group">
            <template slot="card-header">Aantal fte casemanagement per netwerk</template>
            <div slot="card-body">
              <grouped-bar-chart :marginleft="150" :ytitle="'Aantal netwerken'" :x="[getSortedDataCount8(group).x]"
                :y="[getSortedDataCount8(group).y]" />
            </div>
          </card>
        </div>
      </div>

      <div class="mb-3 row"></div>
      <div class="mb-3 row"></div>

      <hr style="border: 3px solid black;">

      <div class="mb-3 row"></div>

      <!-- Vraag 9-12 -->
      <div class="row">
        <h2>Samenwerking</h2>
        <!-- <h5>Vraag 9-12</h5> -->
        <div
          v-for="(group, index) in [visualisatie_groep[10], visualisatie_groep[11], visualisatie_groep[12], visualisatie_groep[13]]"
          :key="group" class="col-sm-6">
          <card :cardId="'test' + group">
            <template slot="card-header"
              style="max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{
          cardTitlesQ9_12[index] }}</template>
            <div slot="card-body">
              <horizontal-bar-chart :marginleft="300" :xtitle="'Aantal netwerken'" :x="getSortedDataCounts(group).x"
                :y="getSortedDataCounts(group).y" />
            </div>
          </card>
        </div>
      </div>

      <div class="mb-3 row"></div>
      <div class="mb-3 row"></div>

      <hr style="border: 3px solid black;">

      <div class="mb-3 row"></div>

      <!-- Vraag 13 -->
      <div class="row">
        <h2>Financiering</h2>
        <div v-for="group in [visualisatie_groep[14]]" :key="group" class="col-sm-6">
          <card :cardId="'test' + group">
            <template slot="card-header"> Financiering netwerken (salaris netwerkcoördinator en netwerkactiviteiten)
            </template>
            <div slot="card-body">
              <horizontal-bar-chart :marginleft="300" :xtitle="'Aantal netwerken'" :x="getSortedDataCounts(group).x"
                :y="getSortedDataCounts(group).y" />
            </div>
          </card>
        </div>
      </div>


    </div>
  </div>
</template>


<script>
import Card from '../components/Card'
import HorizontalBarChart from '../components/HorizontalBarChartNumbersNIK'
import GroupedBarChart from '../components/GroupedBarChartNIK'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Netwerken In Kaart Resultaten',
  },
  name: 'NetwerkInKaartResultaten',
  components: { Card, HorizontalBarChart, GroupedBarChart },
  data() {
    return {
      allData: [],
      allDataQ1: [],
      allDataQ6Totals: [],
      filteredData: [],
      CheckSamenwerking: [],
      questions: [],
      AntwoordList: [],
      CountList: [],
      visualisatie_groep: [],
      visualisatie_groep_Q1: [],
      cardTitlesQ1: [
        'Aantal netwerken dat samenwerkt met % gemeenten (van alle gemeenten binnen het netwerk).',
        'Aantal netwerken dat formele samenwerkingsafspraken heeft met % gemeenten (van alle gemeenten binnen het netwerk)',
        'Aantal netwerken waarbij % gemeenten (van alle gemeenten binnen het netwerk) onderdeel is van stuurgroep of bestuur van het netwerk'
      ],
      cardTitlesQ345: [
        'Aantal netwerken dat samenwerkt met betreffende organisatie',
        'Aantal netwerken met formele samenwerkingsafspraken met betreffende organisatie',
        'Aantal netwerken waarbij organisatie onderdeel is van stuurgroep of regionaal bestuur'
      ],
      cardTitlesQ6: [
        'Aantal uur (betaalde) netwerkcoördinatie per week',
        'Totaal aantal uur ondersteuning per week'
      ],
      cardTitlesQ7: [
        'Aantal uur ondersteuning door secretariaat per week',
        'Aantal uur ondersteuning door ICT per week',
        'Aantal uur ondersteuning door beleidsmedewerker per week',
        'Aantal uur ondersteuning voor (centraal) aanmeldpunt (ipv via aanmeldportaal)'   
      ],
      cardTitlesQ9_12: ['Relatie tussen netwerkcoördinator en casemanagers dementie',
        'Betrokkenheid van netwerken bij regionale overleggen',
        'Netwerken waarmee dementienetwerk samenwerkt',
        'Netwerken die regionaal of met andere regio’s afspraken hebben over specifieke doelgroepen van mensen met dementie'
      ]
    }
  },
  mounted() {
    // Call the loadData method when the component is mounted
    this.loadData();
  },
  methods: {
    // Load data for all questions but Q1
    loadData() {
      this.$http.get(`api/netwerkcoordinatorsnetwerkinkaart/get_data_per_question/`).then(
        (response) => {
          this.allData = response.data.all_other_data;
          this.allDataQ6Totals = response.data.Q6_7_totals;
          this.visualisatie_groep = [...new Set(this.allData.map(d => (d.visualisatie_groep)))];
        },
        (error) => {
          console.log(error);
        }
      )
      // Load data for Q1
      this.$http.get(`api/netwerkcoordinatorsnetwerkinkaart/get_answers_Q1/`).then(
        (response) => {
          this.allDataQ1 = response.data;
          this.visualisatie_groep_Q1 = [...new Set(this.allDataQ1.map(d => (d.vraag)))];
        },
        (error) => {
          console.log(error);
        }
      )
      // Load check for Q3
      this.$http.get(`api/netwerkcoordinatorsnetwerkinkaart/check_necessary_samenwerkingsverbanden/`).then(
        (response) => {
          this.CheckSamenwerking = response.data;
        },
        (error) => {
          console.log(error);
        }
      )

    },

    // To sort y alphabetically for most of the questions
    getSortedDataCounts(group) {
      const groupedData = {};

      // Group data by antwoord_tekst and sum up the counts
      this.allData
        .filter(item => item.visualisatie_groep === group)
        .forEach(item => {
          if (!groupedData[item.antwoord_tekst]) {
            groupedData[item.antwoord_tekst] = 0;
          }
          groupedData[item.antwoord_tekst] += item.counts;
        });

      // Convert the grouped data into arrays for x and y values
      const sortedData = Object.keys(groupedData)
        .sort((a, b) => b.localeCompare(a)) // Reverse alphabetical sorting
        .map(key => ({
          x: groupedData[key],
          y: key
        }));

      return {
        x: sortedData.map(item => item.x),
        y: sortedData.map(item => item.y),
      };
    },

    // Used for sorting above function
    // getSortedDataCounts(group) {
    // const sortOrder = ['0-4 uur', '5-8 uur', '9-12 uur','13-16 uur', '17-20 uur', '21-24 uur', '25-32 uur', '> 32 uur'];

    // const data = this.allData
    //   .filter(item => item.visualisatie_groep === group)
    //   .sort((a, b) => sortOrder.indexOf(a.antwoord_tekst) - sortOrder.indexOf(b.antwoord_tekst));

    // return {
    //   x: data.map(item => item.counts),
    //   y: data.map(item => item.antwoord_tekst),
    // };
    // },

    // Sort counts for Q6 & 7
    getSortedDataCounts6_7(group) {
      const sortOrder = ['0 uur', '1-4 uur', '5-8 uur', '9-12 uur', '13-16 uur', '17-20 uur', '21-24 uur', '25-32 uur', '> 32 uur'];

      // Initialize counts dictionary with default counts of 0
      const countsDictionary = Object.fromEntries(sortOrder.map(item => [item, 0]));

      const data = this.allData
        .filter(item => item.visualisatie_groep === group);

      // Update counts based on data
      data.forEach(item => {
        const key = item.antwoord_tekst;

        if (Object.prototype.hasOwnProperty.call(countsDictionary, key)) {
          countsDictionary[key] += item.counts;
        }
      });

      return {
        y: Object.values(countsDictionary),
        x: sortOrder,
      };
    },

    // Sorts for Q8
    getSortedDataCount8(group) {

      const sortOrder8 = ['0-10 fte', '11-20 fte', '21-30 fte', '31-40 fte', '41-50 fte', '51+ fte'];

      // Initialize counts dictionary with default counts of 0
      const countsDictionary = Object.fromEntries(sortOrder8.map(item => [item, 0]));

      const data = this.allData
        .filter(item => item.visualisatie_groep === group);

      // Update counts based on data
      data.forEach(item => {
        const key = item.antwoord_tekst;

        if (Object.prototype.hasOwnProperty.call(countsDictionary, key)) {
          countsDictionary[key] += item.counts;
        }
      });

      return {
        y: Object.values(countsDictionary),
        x: sortOrder8,
      };

    },


    // Old sorts for percentages, new one is below
    // For most questions, where percentages are involved
    // getSortedDataPercentage(group) {
    // const data = this.allData
    //   .filter(item => item.visualisatie_groep === group)
    //   .sort((a, b) => a.percentage - b.percentage);

    // return {
    //   x: data.map(item => item.percentage),
    //   y: data.map(item => item.antwoord_tekst),
    // };
    // },

    // Sort counts for most percentage vizualizations
    getSortedDataPercentage(group) {
      // Sort some answers first, if they are in the list
      const sortOrder = ['Nee, niet betrokken', 'Nee, er zijn geen afspraken', 'Nee', 'Niemand'];

      const data = this.allData
        .filter(item => item.visualisatie_groep === group)
        .sort((a, b) => {
          const indexA = sortOrder.indexOf(a.antwoord_tekst);
          const indexB = sortOrder.indexOf(b.antwoord_tekst);

          // If both values are in sortOrder, sort based on their order
          if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
          }

          // If only one value is in sortOrder, prioritize it
          if (indexA !== -1) {
            return -1;
          }

          if (indexB !== -1) {
            return 1;
          }

          // For other values, use the default sorting based on percentage
          return a.percentage - b.percentage;
        });
      console.log("Sorted Data Percentage:", data); // Add this line to log the sorted data

      return {
        x: data.map(item => item.percentage),
        y: data.map(item => item.antwoord_tekst),
      };
    },
    // Return vizualization groups
    getQuestionperGroup(group) {
      const data = this.allData
        .filter(item => item.visualisatie_groep === group);

      return {
        vraag: data.map(item => item.vraag),
      };
    },

    // Sort Q1
    getQuestionPerGroupQ1(group) {
      const sortOrderQ1 = ['0-50 %', '51-60 %', '61-70 %', '71-80 %', '81-90 %', '91-100 %'];

      // Initialize counts dictionary with default counts of 0
      const countsDictionary = Object.fromEntries(sortOrderQ1.map(item => [item, 0]));

      const data = this.allDataQ1
        .filter(item => item.vraag === group);

      // Update counts based on data
      data.forEach(item => {
        const key = item.ratio;

        if (Object.prototype.hasOwnProperty.call(countsDictionary, key)) {
          countsDictionary[key] += item.counts;
        }
      });

      return {
        x: Object.values(countsDictionary),
        y: sortOrderQ1,
      };
    },

    // Sort questions on totals on Q6 & 7
    getSortedTotalsQ6_7() {

      const sortOrderTotals_6_7 = ['0-20 uur', '21-40 uur', '41-60 uur', '61-80 uur', '81-100 uur', '> 100 uur'];

      // Initialize counts dictionary with default counts of 0
      const countsDictionary = Object.fromEntries(sortOrderTotals_6_7.map(item => [item, 0]));

      const data = this.allDataQ6Totals

      // Update counts based on data
      data.forEach(item => {
        const key = item.Q6_7_totals;

        if (Object.prototype.hasOwnProperty.call(countsDictionary, key)) {
          countsDictionary[key] += item.counts;
        }
      });

      return {
        x: Object.values(countsDictionary),
        y: sortOrderTotals_6_7
      }

    },

  },
}


</script>

<style scoped>
p {
  margin-left: 10px;
}

p.border-style {
  border-style: ridge;
}

.button {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.my-button-group {
  margin-top: 2em;
}


.my-button-group .btn-outline-primary {
  outline: none;
  box-shadow: none;
  background-color: var(--color-2);
  border-color: var(--color-1);
  color: var(--color-1);
  font-weight: bold;
}

.my-button-group .btn-outline-primary:hover {
  background-color: var(--color-1);
  border-color: var(--color-1);
  color: var(--color-2);
}

.my-button-group .btn-outline-primary:focus {
  background-color: var(--color-14);
  border-color: var(--color-14);
  color: var(--color-2);
}

.my-button-group .btn-outline-primary.active {
  background-color: var(--color-1);
  border-color: var(--color-1);
  color: var(--color-2);
}

.my-button-group .btn-outline-primary {
  flex: 1;
  flex-basis: 120px;
}

.result-information {
  font-weight: bold;
  font-size: 18px;
  color: var(--color-10);
}

.button-space {
  margin: 10px;
}

.icon-space {
  display: flex;
  align-items: center;
}

.custom-icon {
  margin-right: 5px;
}

.custom-select-form {
  font-size: 16px;
  width: 50%;
  font-family: inherit;
  font-weight: normal;
  background: rgba(57, 57, 57, 0.07);
  margin: 12.5px 0;
  height: 40px;
  border: none;
  padding: 0 30px;
  border-radius: 10px;
}

.custom-select-form:hover {
  outline: none;
  box-shadow: none;
  background-color: var(--color-6);
  border-color: var(--color-6);
  font-weight: bold;
}

.custom-select-form:focus {
  outline: none;
  box-shadow: none;
  background-color: var(--color-6);
  /*  border-color: var(--color-6);*/
  border: 2px var(--color-14) solid !important;
  font-weight: bold;
}

.filter-space {
  margin-top: 5em;
}
</style>
